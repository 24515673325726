import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationSettings } from '../../../shared/application-settings';
import { AppPropertiesService } from '../../app-properties.service';
import { ChartTime } from './models/chartTime';
import { IPlcData } from './models/plcdata';

@Injectable()
export class ChartService {
	public resultsFromPLC: IPlcData;
	public chartTime: ChartTime;
	private hasChanged = false;
	public getData: string;
	public returnData: string;
	public baseUrl: string;

	public lineChartData: Array<any> = [
		{ data: [] }
	];
	public lineChartLabels: Array<any> = [];

	private _lineChartData: Array<any> = [
		{ data: [] }
	];
	private _lineChartLabels: Array<any> = [];

	private timer: any;
	private hoseId: number;

	// Variables for the continually reading the PLC values
	constructor(
		private http: HttpClient,
		private appSettings: ApplicationSettings,
		private appPropertiesService: AppPropertiesService) {
		this.chartTime = new ChartTime();
		this.chartTime.SetStartAndCurrentTime((new Date()).getTime());
		this.getData = 'Stopped';
		this.baseUrl = this.appSettings.retrieveUrl();
	}

	onStartTest(hoseId: number): void {
		if (this.getData === 'RUNNING') {
			return;
		}

		this.hoseId = hoseId;
		this.getData = 'RUNNING';
		this._lineChartLabels = [];
		this._lineChartData = [{ data: [] }];
		this.chartTime.SetStartAndCurrentTime((new Date()).getTime());

		this.timer = setInterval(this.getUpdate(), 1000);
	}

	onStopTest(): void {
		this.getData = 'STOPPED';
		clearInterval(this.timer);
	}

	getUpdate(): any {
		const self: ChartService = this;
		return () => {
			this.chartTime.currentTime = (new Date()).getTime();
			self.makePost();
		};
	}

	makePost(): void {
		this.lineChartLabels = [];
		this.lineChartData = [{ data: [] }];
		this.saveDataToServer();
	}

	saveDataToServer(): void {
		let par: string = '?id=' + this.hoseId;
		par = par + '&seconds=' + (this.chartTime.totalSeconds()).toString();
		par = par + '&recordDate=' + '10 Apr 16 08:15:01';
		par = par + '&pressureData=' + (this.resultsFromPLC.pressure);

		this.http.post(
			this.baseUrl + '/api/HydroTestChart/InsertPressure' + par, { name }
		)
			.subscribe((res) => { this.returnData = <any>res; });



		this._lineChartLabels.push(this.chartTime.totalSeconds());
		this._lineChartData[0].data.push(this.resultsFromPLC.pressure);

		this.lineChartLabels = this._lineChartLabels;
		this.lineChartData = this._lineChartData;
		this.hasChanged = true;
	}

	clearTest(hoseId: number): void {

		this.http.post(
			this.baseUrl + '/api/HydroTestChart/ResetPressure?id=' + hoseId,
			{
				recordDate: '10 Apr 16 08:15:01',
				seconds: (this.chartTime.totalSeconds()).toString()
			}
		)
			.subscribe((res) => { this.returnData = <string>res; });
	}

	getChart(id: number) {
		return this.http.get<any>(this.baseUrl + `/api/HydroTestHose/Chart?id=${id}`);
	}

	// These 2 functions are used to check if a new data point has been added.
	public dataHasChanged(): boolean {
		return this.hasChanged;
	}

	public setHasChanged(changed: boolean) {
		this.hasChanged = changed;
	}
}
