<div *ngIf="hoseDetail.Id==0" style="color: red; ">
    <h3>You must press save before charting.</h3>
</div>
<div *ngIf="hoseDetail.Id>0">
    <div *ngIf="hydroTest && hoseDetail">

        <table>
            <tr>
                <td>
                    <div *ngIf="!isTestRunning && !hasTestResults">
                        <button (click)="onStartTest()">Start Hydro Test</button>
                    </div>
                    <div *ngIf="isTestRunning">
                        <button (click)="onStopTest()">Stop Hydro Test</button>
                    </div>
                    <div *ngIf="!isTestRunning && hasTestResults">
                        <button (click)="clearTestPressed()">Clear Test</button>
                        <div *ngIf="isClearTestPressed">
                            <br>
                            <button (click)="clearTest()">I am sure I want to remove this test</button>
                            &nbsp;&nbsp;<button (click)="cancelclearTest()">Cancel clear test</button>
                        </div>
                    </div>
                    <!--<div>
                    test running {{isTestRunning}}
                    has results {{hasTestResults}}
                </div>-->
                </td>
                <td>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;

                </td>
                <td rowspan="4">
                    <div *ngIf="localService.localGLHoseFlag">
                        <div *ngIf="localService.serverStatus">
                            <label style="color: green;font-size: 18px;">local communications Working</label>
                        </div>
                        <div *ngIf="!localService.serverStatus">
                            <label style="color: red;font-size: 18px;">start local communications</label>
                        </div>
                    </div>
                    <!-- <h1><b>Current Pressure: {{localPlcValue.pressure}}</b></h1> -->
                    <h1><b>Current Pressure: {{plcService.resultsFromPLC.pressure}}</b></h1>
                </td>
            </tr>
            <tr>
                <td>

                    <p>Test Status: {{plc.getData}}</p>

                    Duration:

                    <select name="time" [(ngModel)]="chart.selectedTime"
                        [disabled]="!(!isTestRunning && !hasTestResults)" (ngModelChange)="refreshChart()">
                        <option *ngFor="let time of chart.times" [value]="time.value">
                            {{time.label}}
                        </option>
                    </select>

                </td>



            </tr>
            <tr>
                <td>
                    <p>Test Time: {{plc.chartTime.minutes()}} : {{plc.chartTime.seconds()}}</p>
                </td>
            </tr>
        </table>
        <div id='chart'> </div>
        <div style="height:500px;">
        </div>
        <div *ngIf="!plc.lineChartData.length && !plc.lineChartLabels.length">
            <h1>No chart data available</h1>
        </div>
    </div>