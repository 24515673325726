import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { D3, NgxD3Service } from '@katze/ngx-d3';
import { Observable } from 'rxjs';
import { DataService } from '../../data/data-service';
import { IHydroTest } from '../../hydro-test/hydro-test-model/hydro-test';
import { IHose } from '../hose-model/hose';
import { CalibrationService } from './calibration.service';
import { ChartService } from './chart.service';
import { Chart } from './models/chart';
import { IPlcData } from './models/plcdata';
import { ReadLocalService } from './readlocal.service';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'http-chart',
	templateUrl: './chart.component.html'
})

export class ChartComponent implements OnInit, OnDestroy, AfterViewInit {
	chart: Chart;
    private plcDataRetrieveSubscription: any; 
	// Declare d3 objects
	private d3: D3;
	private parentNativeElement: any;

	// Provides global scope for variables
	x; // x-axis
	y; // y-axis
	svg; // html element

	// How often updates to the chart should be checked for (setting above 2000 will cause issues)
	// The lower it is, the less delay, but, the more server resources used (still not a lot, it's very light)
	// The higher it is, the lighter it is on the server, but possibly more delay
	// 100ms should be fine by most standards
	checkInterval = 10;
	interval; // global scope

	@ViewChild('chart', { static: true }) chartRef: ElementRef;
	@ViewChild('myHydroTestChart', { static: true }) canvasRef: ElementRef;

	@Input() hoseDetail: IHose;

	public hydroTest: IHydroTest;
	public lineChartType = 'line';
	public lineChartOptions: any = {
		animation: false,
		responsive: true
	};

	public seriesData: Observable<number[]>;
	private categories: any[] = [];

	private plc: ChartService;

	private isTestRunning = false;
	private hasTestResults;
	private isClearTestPressed = false;

	assemblySubscription: any;
	// assemblyDetails: Array<Assembly> = [];
	assemblyHasChanged: boolean;

	// Values also for aesthetics, but more for fitting the graph to the page
	// changing these could break how the graph appears so be careful

	margin = { top: 30, right: 30, bottom: 30, left: 60 };
	width = 800;
	height = window.innerHeight - this.margin.top - 300;

	localPlcValue: IPlcData = { date: '0', time: '0', pressure: '-1' };
	errorMessage = '';

	localService: ReadLocalService;
	gotPLCData: any;
	plcDataCalibrated: IPlcData = { date: '0', time: '0', pressure: '-1' }; 

	constructor(
		private plcService: ChartService
		, d3Service:NgxD3Service
		, element: ElementRef
		, private dataService: DataService
		, private readLocalService: ReadLocalService
		, private calibrationService: CalibrationService) {

			this.plc = plcService;
			this.d3 = d3Service.getD3();
			this.chart = new Chart();
			this.parentNativeElement = element.nativeElement;
			console.log('chart::constructor: ' + this.hoseDetail);
			this.localService = readLocalService;
	}

	ngOnInit() {
		
		this.plcService.resultsFromPLC = this.localPlcValue;
	
		console.log("chart.component::ngOnInit");

		this.plcDataRetrieveSubscription = 	this.dataService.evGotData.subscribe((data: string) => this.GotData(data));
		this.gotPLCData = this.localService.evGotPlcValue.subscribe((data: string) => this.GotPlcData(data));

		if (this.dataService.gotData) {
			this.GotData('');
		}
	}

	GotData(val: string) {
		this.hydroTest = this.dataService.HydroTest;
		this.refreshChart();

		this.localService.getMacAddress()
			.subscribe(() => this.GotMacAddress());
	}

	GotMacAddress(): void {
		console.log('GotMacAddress:' + this.localService.macAddress);
		this.calibrationService.getCalibrationData(this.dataService.HydroTest.PLC, this.localService.macAddress)
			.subscribe(data =>{ this.calibrationService.pcCalibrationList = data; this.LocalServiceReady();});
	}

	LocalServiceReady(): void {
		console.log('STARTING LOCAL HYDROTEST SERVICE!')
		this.localService.stop();

		this.localService.start();
	}

	GotPlcData(val: string) {
		//console.log('Got the PLC Values !!!!!!!!!!!!!!!!!')
		// tslint:disable-next-line:max-line-length
		//this.localPlcValue.pressure = this.calibrationService.calibrateValues(this.readLocalService.localPlcValue.pressure, this.hydroTest.PLC).toString();
		
		//console.log(this.readLocalService.localPlcValue.pressure);
		
		this.plcDataCalibrated = this.localPlcValue;
		this.plcDataCalibrated.pressure = this.calibrationService.calibrateValues(this.readLocalService.localPlcValue.pressure, this.hydroTest.PLC).toString(); 
		
		console.log('GotPLCData::Pressure' + this.plcDataCalibrated.pressure);
		this.plcService.resultsFromPLC = this.plcDataCalibrated; //this.localPlcValue;

	}

	clearTestPressed(): void {
		this.isClearTestPressed = true;
	}

	cancelclearTest(): void {
		this.isClearTestPressed = false;
	}

	refreshChart(): void {
		this.clearChart();
		this.plc.getChart(this.hoseDetail.Id).subscribe(res => {
			for (const obj of res) {
				this.plc.lineChartLabels.push(obj.Seconds);
				this.plc.lineChartData[0].data.push(obj.Pressure);

			}
			console.log(res);
			if (res.length > 0) {
				this.drawChart();
				this.hasTestResults = true;
			}
			this.seriesData = Observable.of(res);
		});
	}

	clearChart(): void {
		this.plc.lineChartLabels = [];
		this.plc.lineChartData = [{ data: [] }];
		this.hasTestResults = false;
	}

	ngOnDestroy() {
		//this.dataService.evGotData.unsubscribe();
		this.plcDataRetrieveSubscription.unsubscribe();
		console.log('chart::ngOnDestroy:: FIRED EVENT');
		this.plc.onStopTest();
		this.localService.stop();
		this.gotPLCData.unsubscribe();
	}

	ngAfterViewInit() {
		// this.drawLines();
		// Creating and making a variable for the svg element our chart is housed in
		const d3ParentElement = this.d3.select(this.parentNativeElement);

		this.svg = d3ParentElement.select('#chart').append('svg').attr('width', this.width + this.margin.left + this.margin.right)
			.attr('height', this.height + this.margin.top + this.margin.bottom).append('g')
			.attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');
	}

	onStartTest(): void {
		this.plc.onStartTest(this.hoseDetail.Id);
		this.clearTest();
		this.drawChart();

		this.hasTestResults = true;
		this.isTestRunning = true;

		// Checks for changes every (variable) ms, then applies those changes to the chart
		this.interval = setInterval(() => {
			if (this.plc.dataHasChanged()) {
				this.drawPoint(this.plc.lineChartLabels[this.plc.lineChartLabels.length - 1],
					this.plc.lineChartData[0].data[this.plc.lineChartData[0].data.length - 1]);
				this.plc.setHasChanged(false);
			}
		}, this.checkInterval);
	}

	onStopTest(): void {
		this.isTestRunning = false;
		this.plc.onStopTest();
		clearTimeout(this.interval);
	}

	clearTest(): void {
		this.plc.clearTest(this.hoseDetail.Id);
		this.clearChart();
		this.clearPoints();
		this.removeChart();
		this.isClearTestPressed = false;
	}

	drawPoint(pointX: number, pointY: number) {
		// console.log("Drawing point at (" + pointX + ", " + pointY + ")");
		// Adds a circle at (pointX, pointY)
		this.svg
			.append('circle')
			.attr('cx', this.x(pointX))
			.attr('cy', this.y(pointY))
			.attr('r', this.chart.pointRadius)
			.attr('fill', this.chart.pointColor);

		// Draws line between last point and new point
		this.svg.append('line')
			.attr('x1', this.x(this.chart.previousX))
			.attr('y1', this.y(this.chart.previousY))
			.attr('x2', this.x(pointX))
			.attr('y2', this.y(pointY))
			.attr('stroke-width', this.chart.lineWidth)
			.attr('stroke', this.chart.lineColor);

		// Sets current point as previous point (for purposes of drawing line to next point)
		this.chart.previousX = pointX;
		this.chart.previousY = pointY;
	}

	clearPoints() {
		this.svg.selectAll('circle').remove();
		this.svg.selectAll('line').remove();

		this.chart.previousX = 0;
		this.chart.previousY = 0;
	}

	drawChart() {
		// Below here is all the stuff for creating the graph

		// Scaling for the x and y axis
		this.x = this.d3.scaleLinear().domain([0, this.chart.selectedTime]).range([0, this.width]);
		this.y = this.d3.scaleLinear().domain([0, this.hydroTest.testPressure * 1.4]).range([this.height, 0]);

		// Declaring the axises
		const xAxis = this.d3.axisBottom<number>(this.x).ticks(this.chart.numTicksX);
		const yAxis = this.d3.axisLeft<number>(this.y).ticks(this.chart.numTicksY);

		// These add both the y and x axises to the svg element we just made
		this.svg.append('g')
			.attr('class', 'x axis')
			.attr('transform', 'translate(0,' + this.height + ')')
			.call(xAxis);

		this.svg.append('g')
			.attr('class', 'y axis').call(yAxis);

		// for(var i = 0; i < this.plc.lineChartLabels.length; i++) {
		//   this.drawPoint(this.plc.lineChartLabels[i],
		//     this.plc.lineChartData[0].data[i]);
		// }

		const ave: number[] = [0, 0, 0, 0, 0];

		for (let i = 0; i < this.plc.lineChartLabels.length; i++) {
			// rolling average of 5
			ave[0] = ave[1];
			ave[1] = ave[2];
			ave[2] = ave[3];
			ave[3] = ave[4];
			ave[4] = this.plc.lineChartData[0].data[i];

			const avePoint = (ave[0] + ave[1] + ave[2] + ave[3] + ave[4]) / 5;

			// draws the average point

			this.drawPoint(this.plc.lineChartLabels[i],
				avePoint);
		}

		this.svg.append('line')
			.attr('x1', this.x(0))
			.attr('x2', this.x(this.chart.selectedTime))
			.attr('y1', this.y(this.hydroTest.testPressure))
			.attr('y2', this.y(this.hydroTest.testPressure))
			.attr('stroke-width', 1)
			.attr('stroke', 'gray');

		console.log('Test Pressure = ' + this.hydroTest.testPressure);

		// Graph is created, all necessary values are initiated ^^^
	}

	removeChart() {
		this.svg.selectAll('g').remove();
	}
}

