import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Response } from '@angular/http';
import { Observable, Observer, Subject } from 'rxjs';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { catchError, map, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { BranchlabelprinterService } from 'src/app/branchlabelprinters/branchlabelprinter-service';
import { ApplicationSettings } from '../../../shared/application-settings';
import { DataService } from '../../data/data-service';
import { IPlcData } from './models/plcdata';

const CHAT_URL = "ws://localhost:5001/chathub";
export interface Message {
	source: string;
	content: any[];
	message: string;
	function: string;
}

@Injectable()
export class ReadLocalService implements OnDestroy{
	public macAddress = '';
	public localPlcValue: IPlcData = { date: '0', time: '0', pressure: '-1' };
	public evGotPlcValue = new Subject<string>();
	public baseUrl: string;
	public plcUrl: string;
	private timer: any;
	private startTime: number;
	private errorMessage: string;

	public respLocalGLHose: any;
	trnsfrData = {
		source: '',
		content: [],
		message: '',
		function: ''
	};
	public serverStatus = false;
	private subject: AnonymousSubject<MessageEvent>;
	public messages: Subject<Message>;
	localGLHoseFlag=false;

	constructor(
		private http: HttpClient,
		private appSettings: ApplicationSettings,
		private dataService: DataService,
		private httpLegacy: HttpClient,
		private brnchlblprnterService: BranchlabelprinterService,
		private auth: AuthService ) {
			this.baseUrl = this.appSettings.retrieveUrl();
			this.plcUrl = this.appSettings.retrievePLCUrl();
			this.connectServer();
			
	}

	ngOnDestroy() {
		this.stop();
	}


	start(): void {
		this.localGLHoseFlag=this.auth.branchHydrotestUpdated();
		if(this.localGLHoseFlag){
			this.getConnectionInfo();
		}
		else{
			this.timer = setInterval(this.getPlcValues(), 1000);
			this.startTime = (new Date()).getTime();
		}
	}



	stop(): void {
		clearInterval(this.timer);
	}

	 getPlcValues(): any {
        let self: ReadLocalService = this;
        
        return () => {
			console.log('reading the old plc: ' + this.dataService.HydroTest.PLC);

            self.httpLegacy.get(`${this.plcUrl}/ReadPLC/ReadPLCPressure?PressureSensor=` + this.dataService.HydroTest.PLC)
                .map((res: Response) => res.json())
                .subscribe(
					(data => {
						this.localPlcValue = data[0];
						console.log('getPlcValues::URL');
						console.log(this.localPlcValue);
						
					})
					, err => console.error(err), 
					() => this.evGotPlcValue.next('')
					);
        };
	}
	

	getPlcValuesNew(): any {       
        return () => {
			console.log(' xxx getPlcValuesNew::reading the new plc: ' + this.dataService.HydroTest.PLC);
			console.log(this.localPlcValue);
			this.messages.next(this.trnsfrData);
            this.evGotPlcValue.next('');
        };
	}


    getMacAddress():  Observable<string> {
		console.log(' getMacAddress()' + `${this.plcUrl}/ReadPLC/GetMacAddress`)
		let branchid = this.auth.getBranchId();

		if (branchid==="0"||branchid==="18"||branchid==="21"||branchid==="5"||branchid==="23"||branchid==="3"||branchid=="27"){
			this.macAddress = "Branch " + branchid;
			return Observable.of("Branch " + branchid);
		}
		else{
		
		return this.httpLegacy.get(`${this.plcUrl}/ReadPLC/GetMacAddress`)
              .map ((response: Response) => this.macAddress = response.json() as string,
                  err => console.error(err)
              );
			  }
			}

		

	getConnectionInfo() {
		let ip: '';
		let type: any;
		let port: number = 502;
		console.log('get connection info');

		if (this.dataService.HydroTest.PLC == "High") {
			type = "HighPressure";
		}
		else {
			type = "LowPressure";
		}


		console.log('type: ' + type);
		let branchid = this.auth.getBranchId();

		console.log('branch: ' + branchid.toString());

		this.brnchlblprnterService.getBrnchLblPrntrData(branchid, type).subscribe(
			resp => {
				//console.log('response: ' + resp.length.toString());
				//console.log(resp);
			if(resp.length!=0){
				ip =resp[0].IP;

				if(resp[0].Port > 1)
					port = resp[0].Port;

				let plcchannel=resp[0].PLCChannel;
					this.trnsfrData = {
						source: 'hydrotest client',
						content: [],
						message: 'Reading From PLC',
						function: 'readFromPlc'
					};
					 this.trnsfrData.content.push(type);
					 this.trnsfrData.content.push(ip);
					 this.trnsfrData.content.push(plcchannel);
					 this.trnsfrData.content.push(port)
					 
					 this.timer = setInterval(this.getPlcValuesNew(), 1000);
					 this.startTime = (new Date()).getTime();	 
			}

		})
	}

	connectServer() {
		this.messages = <Subject<Message>>this.connect(CHAT_URL).pipe(
			map(
				(response: MessageEvent): Message => {
					let data = JSON.parse(response.data)
					return data;
				}
			),
			catchError(error => { throw error }),
			tap({
				error: error => console.log('Error in WebSocket:', error),
				complete: () => {
					this.serverStatus = false;
				}
			}
			)
		);
		//Response section from websocket Start//
		this.messages.subscribe(msg => {
			if (msg.function == "receiveFromPLC") {
				//console.log(msg);
				//this.respLocalGLHose = msg.content[0];
				this.localPlcValue.date = '1';
				this.localPlcValue.time = '2';

				console.log('xxx msg.content[0]' );
				console.log(msg.content[0] );	
				
				 if(+msg.content[0]>65000) {
					this.localPlcValue.pressure = "-1";//msg.content[0] ;
				 }
				 else {
					this.localPlcValue.pressure = msg.content[0] ;
				 }
		
			}
			else {
				console.log("Response from websocket: " + msg.message);
			}
		});
		//Response section from websocket End//
	}
	public connect(url): AnonymousSubject<MessageEvent> {
		this.subject = this.create(url);
		return this.subject;
	}

	//Connecting websocket//
	private create(url): AnonymousSubject<MessageEvent> {
		let ws = new WebSocket(url);
		ws.onopen = openevent => {
			console.log("Successfully connected: " + url);
			this.serverStatus = true;
			this.trnsfrData = {
				source: 'hydrotest client',
				content: [],
				message: 'register client',
				function: 'registerAngularClient'
			};
			this.messages.next(this.trnsfrData);
			// this.receivePrinterList();
		}

		let observable = new Observable((obs: Observer<MessageEvent>) => {
			ws.onmessage = obs.next.bind(obs);
			ws.onerror = obs.error.bind(obs);
			ws.onclose = obs.complete.bind(obs);
			return ws.close.bind(ws);
		});
		let observer = {
			error: null,
			complete: null,
			next: (data: Object) => {  //sending messge to websocket
				console.log('Message sent to websocket: ', data);
				if (ws.readyState === WebSocket.OPEN) {
					ws.send(JSON.stringify(data));
				}
				else {
					this.serverStatus = false;
				}
			}
		};
		return new AnonymousSubject<MessageEvent>(observer, observable);
	}


}


